import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import PostTile from '../../../PostTile';
import { cleanExcerpt, cleanTitle, formatDate } from '../../../../utils/postUtils';

import styles from './recentPosts.module.scss';

const RecentPosts = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(sort: { fields: [date], order: DESC }, limit: 4) {
            edges {
              node {
                id
                slug
                title
                content
                excerpt
                date
                modified
                author {
                  name
                }
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxHeight: 250, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allWordpressPost.edges;
        const recentPosts = _map(posts, ({ node }) => {
          const { id, slug, title, content, date } = node;
          const author = _toLower(node.author.name);

          const fluidFeaturedImage = _get(
            node,
            'featured_media.localFile.childImageSharp.fluid',
            null,
          );

          return (
            <PostTile
              key={id}
              title={cleanTitle(title)}
              date={formatDate(date)}
              imageFluid={fluidFeaturedImage}
              author={author}
              excerpt={cleanExcerpt(content)}
              slug={slug}
              isOnBlogList={false}
            />
          );
        });
        return (
          <div className={styles.wrapper}>
            <div className={styles.sectionHeader}>Recent Posts</div>
            <div className={styles.postsContainer}>{recentPosts}</div>
          </div>
        );
      }}
    />
  );
};

export default RecentPosts;
