import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './jumbotron.module.scss';

const quote = (
  <p className={styles.quoteCopy}>
    &quot;When we open up about our emotional challenges, admitting we are not perfect, we give
    others permission to share their struggles. Together we realize there is hope and we do not have
    to suffer&nbsp;alone.&quot;
  </p>
);

const author = <p className={styles.author}>Reyna I. Aburto</p>;

const Jumbotron = () => (
  <StaticQuery
    query={graphql`
      query {
        parents: file(relativePath: { eq: "jumbotron/home_5.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kids1: file(relativePath: { eq: "jumbotron/home_1.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kids2: file(relativePath: { eq: "jumbotron/home_2.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kids3: file(relativePath: { eq: "jumbotron/home_3.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kids4: file(relativePath: { eq: "jumbotron/home_6.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        kids5: file(relativePath: { eq: "jumbotron/home_4.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const { parents, kids1, kids2, kids3, kids4, kids5 } = data;

      return (
        <div className={styles.wrapper}>
          <div className={styles.quoteContainer}>
            {quote}
            {author}
          </div>

          <div className={styles.imageContainer}>
            <Img
              fluid={parents.childImageSharp.fluid}
              alt="Blake + Emily - Autism Parenting Journey"
              className={styles.image}
            />

            <Img
              fluid={kids1.childImageSharp.fluid}
              alt="Blake + Emily - Autism Parenting Journey"
              className={styles.image}
            />

            <Img
              fluid={kids2.childImageSharp.fluid}
              alt="Blake + Emily - Autism Parenting Journey"
              className={styles.image}
            />

            <Img
              fluid={kids3.childImageSharp.fluid}
              alt="Blake + Emily - Autism Parenting Journey"
              className={styles.image}
            />

            <Img
              fluid={kids4.childImageSharp.fluid}
              alt="Blake + Emily - Autism Parenting Journey"
              className={styles.image}
            />

            <Img
              fluid={kids5.childImageSharp.fluid}
              alt="Blake + Emily - Autism Parenting Journey"
              className={styles.image}
            />
          </div>
        </div>
      );
    }}
  />
);

export default Jumbotron;
