import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './intro.module.scss';

const Intro = () => (
  <StaticQuery
    query={graphql`
      query {
        leaf: file(relativePath: { eq: "logos/leaf.png" }) {
          childImageSharp {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={({ leaf }) => {
      return (
        <div className={styles.introWrapper}>
          <Img
            fluid={leaf.childImageSharp.fluid}
            alt="Autism Parenting Journey"
            className={styles.leaf}
          />
          <section className={styles.copy}>
            Join us on our journey as we share our ups, downs, and all-arounds of being parents to
            two kids with autism spectrum disorder (ASD) and two neurotypical kids. Like all
            parents, we&apos;re just trying to do the best we can everyday in our predictably
            unpredictable&nbsp;world.
          </section>
          <div className={styles.buttonContainer}>
            <Link to="/about">
              <div className={styles.button}>Read More</div>
            </Link>
          </div>
        </div>
      );
    }}
  />
);

export default Intro;
