import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import Dotdotdot from 'react-dotdotdot';
import Img from 'gatsby-image';
import Avatar from '../Avatar';

import styles from './postTile.module.scss';

const PostTile = ({ title, date, imageFluid, author, excerpt, slug, isOnBlogList }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          defaultImg: file(relativePath: { eq: "misc/default_post_img.png" }) {
            childImageSharp {
              fluid(maxHeight: 250, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const { defaultImg } = data;

        return (
          <div className={isOnBlogList ? styles.tileWrapperBlogList : styles.tileWrapper}>
            <Link to={`/blog/${slug}`}>
              <div className={isOnBlogList ? styles.topImageOnList : styles.topImage}>
                {imageFluid && (
                  <Img fluid={imageFluid} objectFit="cover" objectPosition="50% 50%" />
                )}

                {!imageFluid && defaultImg && (
                  <Img
                    fluid={defaultImg.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                )}
              </div>
            </Link>

            <div className={styles.postDetails}>
              <Link to={`/blog/${slug}`}>
                <Dotdotdot clamp={isOnBlogList ? 3 : 2} className={styles.postTitle} useNativeClamp>
                  <h2 dangerouslySetInnerHTML={{ __html: title }} />
                </Dotdotdot>
              </Link>

              <p className={styles.date}>{date}</p>

              <Dotdotdot className={styles.excerpt} clamp={7} useNativeClamp>
                <p dangerouslySetInnerHTML={{ __html: excerpt }} />
              </Dotdotdot>

              <div className={styles.tileFooter}>
                <Avatar author={author} size="medium" />
                <Link to={`/blog/${slug}`} className={styles.buttonSpacing}>
                  <div className={styles.readMoreButton}>Read More</div>
                </Link>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

PostTile.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  imageFluid: PropTypes.object,
  author: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  isOnBlogList: PropTypes.bool.isRequired,
};

PostTile.defaultProps = {
  imageFluid: null,
};

export default PostTile;
