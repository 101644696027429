import React from 'react';
import Layout from '../Layout';
import SEO from '../SEO';
import Jumbotron from './components/Jumbotron';
import Intro from './components/Intro';
import RecentPosts from './components/RecentPosts';

const Home = () => (
  <Layout>
    <SEO title="Autism Parenting Journey" />
    <Jumbotron />
    <Intro />
    <RecentPosts />
  </Layout>
);

export default Home;
